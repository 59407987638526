import * as React from "react"
import Seo from "@components/seo"
import Layout from "@layout"
import Header from "@layout/header/header-one"
import Footer from "@layout/footer/footer-one"
import Banner from "@components/banners/Ai";
import PublicationArea from "@containers/publication-area"
import PubcateArea from "@containers/publication-cate-area"


const PublicationPage = ({pageContext, location }) => (
  <Layout location={location}>
    <Seo title="All Publications" />
    <Header />
    { <Banner
			pageContext={pageContext}
			location={location}
			title="All Publications"
		/> }
    <main className="site-wrapper-reveal">
      <PubcateArea />
      <PublicationArea />
    </main>
    <Footer />
  </Layout>
)

export default PublicationPage
