import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "@ui/wrapper"
import SectionTitle from "@ui/section-title"
import ServiceBox from "@components/box-icon/layout-one"
import { SectionWrap } from "./services-area.style"

const PubcateArea = ({ sectionTitleStyle, servicBoxStyle }) => {
  const pubcateData = useStaticQuery(graphql`
    query PubcatesQuery {
      allPubcateJson(sort: { order: ASC, fields: id }, limit: 9) {
        edges {
          node {
            fields {
              slug
            }
            id
            title
            excerpt
            icon {
              svg {
                publicURL
              }
            }
          }
        }
      }
    }
  `)
  const pubcates = pubcateData.allPubcateJson.edges
  return (
    <SectionWrap>
      <Container>
        <Row>
          <Col lg={12}>
            <SectionTitle
              {...sectionTitleStyle}
              // title="All Categories of Our Publications"
              subtitle="Research Aera of Our Publications"
            />
          </Col>{" "}
        </Row>{" "}
        <Row>
          {" "}
          {pubcates.map(service => {
            if (
              service.node.id === "ai" 
            ) {
              return (
                <Col xl={3} lg={4} md={6} key={service.node.fields.slug}>
                  <ServiceBox
                    {...servicBoxStyle}
                    id={service.node.id}
                    icon={service.node.icon}
                    title={service.node.title}
                    // desc={service.node.excerpt}
                    path={`/publication/${service.node.id}`}
                  />{" "}
                </Col>
              )
            }
          })}
          {pubcates.map(service => {
            if (
              service.node.id === "bi" 
            ) {
              return (
                <Col xl={3} lg={4} md={6} key={service.node.fields.slug}>
                  <ServiceBox
                    {...servicBoxStyle}
                    id={service.node.id}
                    icon={service.node.icon}
                    title={service.node.title}
                    // desc={service.node.excerpt}
                    path={`/publication/${service.node.id}`}
                  />{" "}
                </Col>
              )
            }
          })}
          {pubcates.map(service => {
            if (
              service.node.id === "ps" 
            ) {
              return (
                <Col xl={3} lg={4} md={6} key={service.node.fields.slug}>
                  <ServiceBox
                    {...servicBoxStyle}
                    id={service.node.id}
                    icon={service.node.icon}
                    title={service.node.title}
                    // desc={service.node.excerpt}
                    path={`/publication/${service.node.id}`}
                  />{" "}
                </Col>
              )
            }
          })}
          {pubcates.map(service => {
            if (
              service.node.id === "app" 
            ) {
              return (
                <Col xl={3} lg={4} md={6} key={service.node.fields.slug}>
                  <ServiceBox
                    {...servicBoxStyle}
                    id={service.node.id}
                    icon={service.node.icon}
                    title={service.node.title}
                    // desc={service.node.excerpt}
                    path={`/publication/${service.node.id}`}
                  />{" "}
                </Col>
              )
            }
          })}{" "}
        </Row>{" "}
      </Container>{" "}
      <li />
      <li />
      <li />
      <Container>
        <Row>
          <Col lg={12}>
            <SectionTitle
              {...sectionTitleStyle}
              // title="All Categories of Our Publications"
              subtitle="All Categories of Our Publications"
            />
          </Col>{" "}
        </Row>{" "}
        <Row>
          {" "}
          {pubcates.map(service => {
            if (
              service.node.id === "conferences" 
            ) {
              return (
                <Col xl={3} lg={4} md={6} key={service.node.fields.slug}>
                  <ServiceBox
                    {...servicBoxStyle}
                    id={service.node.id}
                    icon={service.node.icon}
                    title={service.node.title}
                    // desc={service.node.excerpt}
                    path={`/publication/${service.node.id}`}
                  />{" "}
                </Col>
              )
            }
          })}
          {pubcates.map(service => {
            if (
              service.node.id === "journals" 
            ) {
              return (
                <Col xl={3} lg={4} md={6} key={service.node.fields.slug}>
                  <ServiceBox
                    {...servicBoxStyle}
                    id={service.node.id}
                    icon={service.node.icon}
                    title={service.node.title}
                    // desc={service.node.excerpt}
                    path={`/publication/${service.node.id}`}
                  />{" "}
                </Col>
              )
            }
          })}
          {pubcates.map(service => {
            if (
              service.node.id === "monograph" 
            ) {
              return (
                <Col xl={3} lg={4} md={6} key={service.node.fields.slug}>
                  <ServiceBox
                    {...servicBoxStyle}
                    id={service.node.id}
                    icon={service.node.icon}
                    title={service.node.title}
                    // desc={service.node.excerpt}
                    path={`/publication/${service.node.id}`}
                  />{" "}
                </Col>
              )
            }
          })}{pubcates.map(service => {
            if (
              service.node.id === "proceedings"
            ) {
              return (
                <Col xl={3} lg={4} md={6} key={service.node.fields.slug}>
                  <ServiceBox
                    {...servicBoxStyle}
                    id={service.node.id}
                    icon={service.node.icon}
                    title={service.node.title}
                    // desc={service.node.excerpt}
                    path={`/publication/${service.node.id}`}
                  />{" "}
                </Col>
              )
            }
          })}{" "}
        </Row>{" "}
      </Container>{" "}
    </SectionWrap>
  )
}

PubcateArea.defaultProps = {
  sectionTitleStyle: {
    mb: "30px",
    responsive: {
      small: {
        mb: "20px",
      },
    },
  },
  servicBoxStyle: {
    boxStyles: {
      mt: "30px",
    },
  },
}

export default PubcateArea
