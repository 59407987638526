import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "@ui/wrapper"
import Heading from "@ui/heading"
import List, { ListItem } from "@ui/list"
import { SectionWrap } from "./style"
import Line from "@ui/divider/line";
import SectionTitle from "@ui/section-title"

const PublicationAera = ({ sectionTitleStyle, ListThreeStyle }) => {
  const getPubsData = useStaticQuery(graphql`
    query GET_PUBS_DATA {
      pubs: publicationJson {
        pubs {
          author
          category
          journal
          link
          note
          title
          type
          volume
          year
        }
      }
    }
  `)
  const {
    pubs: { pubs },
  } = getPubsData

  const year_list = pubs.map(pub => {
    return pub.year
  })
  const years = [...new Set(year_list.sort().reverse())]

  const textStyle = {
    color:"#6d6fb0",
    display: "unset",
  }

  return (
    <SectionWrap>
      <Container>
        <Row>
          <Col lg={12}>
          <SectionTitle
              {...sectionTitleStyle}
              // title="All Categories of Our Publications"
              subtitle="All of Our Publications"
              style={{marginTop:"-110px"}}
            />
            {years.map(year => {
              return(
              <Row>
                <Col lg={12}>
                  <Heading as="h6" mb="15px">
                    {year}
                  </Heading>
                  <List {...ListThreeStyle}>
                    {pubs.map(pub => {
                      if (pub.year === year) {
                        return (
                          <font size="2">
                            <ListItem>
                              {pub.author + " "}
                              <a
                                display="inline"
                                href={pub.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={textStyle}
                              >
                                {pub.title}
                              </a>
                              <b> {pub.journal} </b>
                              {pub.volume}
                              {pub.year}.
                              <font color="#FF8C00">
                              <div dangerouslySetInnerHTML={{__html:pub.note}} style={{display: "inline"}}></div>
                              </font>
                            </ListItem>
                          </font>
                        )
                      }
                    })}
                  </List>
                  <Line mt="40px" mb="40px" borderStyle="dashed" />
                </Col>
              </Row>
              )
            })}
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  )
}

PublicationAera.defaultProps = {
  sectionTitleStyle: {
    mb: "30px",
    responsive: {
      small: {
        mb: "20px",
      },
    },
  },
  ListThreeStyle: {
    layout: "circle",
  },
}

export default PublicationAera
